export const testRecording = [
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0199375,
    start: 0,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Well",
          confidence: 0.6503906,
          words: [
            {
              word: "well",
              start: 0.81576926,
              end: 1.0199375,
              confidence: 0.6503906,
              punctuated_word: "Well",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0399375,
    start: 0,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Welcome. This",
          confidence: 0.99853516,
          words: [
            {
              word: "welcome",
              start: 0.8978846,
              end: 1.2882692,
              confidence: 0.99853516,
              punctuated_word: "Welcome.",
            },
            {
              word: "this",
              start: 1.8348076,
              end: 2.0399375,
              confidence: 0.9863281,
              punctuated_word: "This",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0599375,
    start: 0,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Welcome. This is test recording.",
          confidence: 0.99902344,
          words: [
            {
              word: "welcome",
              start: 0.8993589,
              end: 1.2903845,
              confidence: 0.99902344,
              punctuated_word: "Welcome.",
            },
            {
              word: "this",
              start: 1.8378204,
              end: 1.9942306,
              confidence: 0.99853516,
              punctuated_word: "This",
            },
            {
              word: "is",
              start: 1.9942306,
              end: 2.228846,
              confidence: 0.98339844,
              punctuated_word: "is",
            },
            {
              word: "test",
              start: 2.228846,
              end: 2.6198716,
              confidence: 0.99902344,
              punctuated_word: "test",
            },
            {
              word: "recording",
              start: 2.6198716,
              end: 2.854487,
              confidence: 0.9995117,
              punctuated_word: "recording.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.0799375,
    start: 0,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "Welcome. This is test recording, and They're simply gonna",
          confidence: 0.9868164,
          words: [
            {
              word: "welcome",
              start: 0.9177451,
              end: 1.3167647,
              confidence: 0.99853516,
              punctuated_word: "Welcome.",
            },
            {
              word: "this",
              start: 1.8753922,
              end: 2.035,
              confidence: 0.99902344,
              punctuated_word: "This",
            },
            {
              word: "is",
              start: 2.035,
              end: 2.2744117,
              confidence: 0.9746094,
              punctuated_word: "is",
            },
            {
              word: "test",
              start: 2.2744117,
              end: 2.6734314,
              confidence: 0.9995117,
              punctuated_word: "test",
            },
            {
              word: "recording",
              start: 2.6734314,
              end: 2.9128432,
              confidence: 0.9995117,
              punctuated_word: "recording,",
            },
            {
              word: "and",
              start: 3.2320588,
              end: 3.4714706,
              confidence: 0.9868164,
              punctuated_word: "and",
            },
            {
              word: "they're",
              start: 3.5512745,
              end: 3.7108824,
              confidence: 0.24914551,
              punctuated_word: "They're",
            },
            {
              word: "simply",
              start: 3.7108824,
              end: 3.950294,
              confidence: 0.79296875,
              punctuated_word: "simply",
            },
            {
              word: "gonna",
              start: 3.950294,
              end: 4.0799375,
              confidence: 0.9746094,
              punctuated_word: "gonna",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.02,
    start: 0,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Welcome. This is test recording.",
          confidence: 0.99902344,
          words: [
            {
              word: "welcome",
              start: 0.910921,
              end: 1.3069736,
              confidence: 0.99902344,
              punctuated_word: "Welcome.",
            },
            {
              word: "this",
              start: 1.8614472,
              end: 2.0198684,
              confidence: 0.99853516,
              punctuated_word: "This",
            },
            {
              word: "is",
              start: 2.0198684,
              end: 2.2574997,
              confidence: 0.97998047,
              punctuated_word: "is",
            },
            {
              word: "test",
              start: 2.2574997,
              end: 2.5743418,
              confidence: 0.99902344,
              punctuated_word: "test",
            },
            {
              word: "recording",
              start: 2.5743418,
              end: 2.8911839,
              confidence: 0.9995117,
              punctuated_word: "recording.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0199375,
    start: 3.02,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "And Simply gonna read from the...",
          confidence: 0.9897461,
          words: [
            {
              word: "and",
              start: 3.2132692,
              end: 3.4451923,
              confidence: 0.9975586,
              punctuated_word: "And",
            },
            {
              word: "simply",
              start: 3.6771154,
              end: 3.986346,
              confidence: 0.9897461,
              punctuated_word: "Simply",
            },
            {
              word: "gonna",
              start: 3.986346,
              end: 4.1409616,
              confidence: 0.97216797,
              punctuated_word: "gonna",
            },
            {
              word: "read",
              start: 4.1409616,
              end: 4.3728848,
              confidence: 0.98046875,
              punctuated_word: "read",
            },
            {
              word: "from",
              start: 4.3728848,
              end: 4.5275,
              confidence: 0.9946289,
              punctuated_word: "from",
            },
            {
              word: "the",
              start: 4.5275,
              end: 4.682115,
              confidence: 0.9863281,
              punctuated_word: "the...",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0399375,
    start: 3.02,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "And I simply gonna read from the effect documentation",
          confidence: 0.98095703,
          words: [
            {
              word: "and",
              start: 3.299079,
              end: 3.4585526,
              confidence: 0.9355469,
              punctuated_word: "And",
            },
            {
              word: "i",
              start: 3.6180263,
              end: 3.6977632,
              confidence: 0.45385742,
              punctuated_word: "I",
            },
            {
              word: "simply",
              start: 3.6977632,
              end: 4.0167103,
              confidence: 0.98095703,
              punctuated_word: "simply",
            },
            {
              word: "gonna",
              start: 4.0167103,
              end: 4.176184,
              confidence: 0.9140625,
              punctuated_word: "gonna",
            },
            {
              word: "read",
              start: 4.176184,
              end: 4.415395,
              confidence: 0.99609375,
              punctuated_word: "read",
            },
            {
              word: "from",
              start: 4.415395,
              end: 4.574868,
              confidence: 0.9921875,
              punctuated_word: "from",
            },
            {
              word: "the",
              start: 4.574868,
              end: 5.074868,
              confidence: 0.99316406,
              punctuated_word: "the",
            },
            {
              word: "effect",
              start: 5.133026,
              end: 5.633026,
              confidence: 0.92578125,
              punctuated_word: "effect",
            },
            {
              word: "documentation",
              start: 5.850658,
              end: 6.0599375,
              confidence: 0.99902344,
              punctuated_word: "documentation",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.0599375,
    start: 3.02,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "And Simply gonna read from the effect documentation two and a",
          confidence: 0.984375,
          words: [
            {
              word: "and",
              start: 3.2185295,
              end: 3.4567647,
              confidence: 0.99609375,
              punctuated_word: "And",
            },
            {
              word: "simply",
              start: 3.695,
              end: 4.012647,
              confidence: 0.9741211,
              punctuated_word: "Simply",
            },
            {
              word: "gonna",
              start: 4.012647,
              end: 4.1714706,
              confidence: 0.984375,
              punctuated_word: "gonna",
            },
            {
              word: "read",
              start: 4.1714706,
              end: 4.330294,
              confidence: 0.9975586,
              punctuated_word: "read",
            },
            {
              word: "from",
              start: 4.330294,
              end: 4.568529,
              confidence: 0.99902344,
              punctuated_word: "from",
            },
            {
              word: "the",
              start: 4.568529,
              end: 5.068529,
              confidence: 0.99853516,
              punctuated_word: "the",
            },
            {
              word: "effect",
              start: 5.1244116,
              end: 5.6244116,
              confidence: 0.9550781,
              punctuated_word: "effect",
            },
            {
              word: "documentation",
              start: 5.9185295,
              end: 6.4185295,
              confidence: 0.9970703,
              punctuated_word: "documentation",
            },
            {
              word: "two",
              start: 6.7126465,
              end: 6.7920585,
              confidence: 0.9379883,
              punctuated_word: "two",
            },
            {
              word: "and",
              start: 6.7920585,
              end: 6.8714705,
              confidence: 0.7807617,
              punctuated_word: "and",
            },
            {
              word: "a",
              start: 6.8714705,
              end: 7.0799375,
              confidence: 0.4921875,
              punctuated_word: "a",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.82,
    start: 3.02,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "And Simply gonna read from the effect documentation, two and a half paragraphs.",
          confidence: 0.98046875,
          words: [
            {
              word: "and",
              start: 3.2959836,
              end: 3.532541,
              confidence: 0.9970703,
              punctuated_word: "And",
            },
            {
              word: "simply",
              start: 3.6902459,
              end: 4.005656,
              confidence: 0.96191406,
              punctuated_word: "Simply",
            },
            {
              word: "gonna",
              start: 4.005656,
              end: 4.1633606,
              confidence: 0.98046875,
              punctuated_word: "gonna",
            },
            {
              word: "read",
              start: 4.1633606,
              end: 4.3210654,
              confidence: 0.99853516,
              punctuated_word: "read",
            },
            {
              word: "from",
              start: 4.3210654,
              end: 4.557623,
              confidence: 0.99902344,
              punctuated_word: "from",
            },
            {
              word: "the",
              start: 4.557623,
              end: 5.057623,
              confidence: 0.99902344,
              punctuated_word: "the",
            },
            {
              word: "effect",
              start: 5.10959,
              end: 5.60959,
              confidence: 0.9692383,
              punctuated_word: "effect",
            },
            {
              word: "documentation",
              start: 5.8981147,
              end: 6.0558195,
              confidence: 0.99853516,
              punctuated_word: "documentation,",
            },
            {
              word: "two",
              start: 6.6866393,
              end: 6.7654915,
              confidence: 0.99902344,
              punctuated_word: "two",
            },
            {
              word: "and",
              start: 6.7654915,
              end: 6.844344,
              confidence: 0.9433594,
              punctuated_word: "and",
            },
            {
              word: "a",
              start: 6.844344,
              end: 6.923197,
              confidence: 0.67333984,
              punctuated_word: "a",
            },
            {
              word: "half",
              start: 6.923197,
              end: 7.2386065,
              confidence: 0.9067383,
              punctuated_word: "half",
            },
            {
              word: "paragraphs",
              start: 7.2386065,
              end: 7.632869,
              confidence: 0.95410156,
              punctuated_word: "paragraphs.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.039937,
    start: 7.84,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "So we can get",
          confidence: 0.9794922,
          words: [
            {
              word: "so",
              start: 8.038077,
              end: 8.196539,
              confidence: 0.9863281,
              punctuated_word: "So",
            },
            {
              word: "we",
              start: 8.196539,
              end: 8.3550005,
              confidence: 0.9794922,
              punctuated_word: "we",
            },
            {
              word: "can",
              start: 8.3550005,
              end: 8.592692,
              confidence: 0.95947266,
              punctuated_word: "can",
            },
            {
              word: "get",
              start: 8.592692,
              end: 8.879937,
              confidence: 0.96875,
              punctuated_word: "get",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0599375,
    start: 7.84,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "So we can get some interesting resell",
          confidence: 0.9770508,
          words: [
            {
              word: "so",
              start: 8.037115,
              end: 8.194808,
              confidence: 0.9765625,
              punctuated_word: "So",
            },
            {
              word: "we",
              start: 8.194808,
              end: 8.3525,
              confidence: 0.984375,
              punctuated_word: "we",
            },
            {
              word: "can",
              start: 8.3525,
              end: 8.589039,
              confidence: 0.9892578,
              punctuated_word: "can",
            },
            {
              word: "get",
              start: 8.589039,
              end: 8.904423,
              confidence: 0.9975586,
              punctuated_word: "get",
            },
            {
              word: "some",
              start: 8.904423,
              end: 9.219808,
              confidence: 0.9770508,
              punctuated_word: "some",
            },
            {
              word: "interesting",
              start: 9.219808,
              end: 9.719808,
              confidence: 0.9458008,
              punctuated_word: "interesting",
            },
            {
              word: "resell",
              start: 9.77173,
              end: 9.899938,
              confidence: 0.4099121,
              punctuated_word: "resell",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.079937,
    start: 7.84,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "So we can get some interesting results for testing.",
          confidence: 0.99560547,
          words: [
            {
              word: "so",
              start: 8.036795,
              end: 8.194231,
              confidence: 0.99853516,
              punctuated_word: "So",
            },
            {
              word: "we",
              start: 8.194231,
              end: 8.351666,
              confidence: 0.9951172,
              punctuated_word: "we",
            },
            {
              word: "can",
              start: 8.351666,
              end: 8.587821,
              confidence: 0.99316406,
              punctuated_word: "can",
            },
            {
              word: "get",
              start: 8.587821,
              end: 8.902693,
              confidence: 0.9995117,
              punctuated_word: "get",
            },
            {
              word: "some",
              start: 8.902693,
              end: 9.138846,
              confidence: 0.99316406,
              punctuated_word: "some",
            },
            {
              word: "interesting",
              start: 9.138846,
              end: 9.638846,
              confidence: 0.9975586,
              punctuated_word: "interesting",
            },
            {
              word: "results",
              start: 9.76859,
              end: 10.004744,
              confidence: 0.9921875,
              punctuated_word: "results",
            },
            {
              word: "for",
              start: 10.004744,
              end: 10.319615,
              confidence: 0.99560547,
              punctuated_word: "for",
            },
            {
              word: "testing",
              start: 10.319615,
              end: 10.634487,
              confidence: 1,
              punctuated_word: "testing.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.0999374,
    start: 7.84,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "So we can get some interesting results for testing.",
          confidence: 0.99609375,
          words: [
            {
              word: "so",
              start: 8.036634,
              end: 8.193942,
              confidence: 0.99902344,
              punctuated_word: "So",
            },
            {
              word: "we",
              start: 8.193942,
              end: 8.35125,
              confidence: 0.9975586,
              punctuated_word: "we",
            },
            {
              word: "can",
              start: 8.35125,
              end: 8.587212,
              confidence: 0.99316406,
              punctuated_word: "can",
            },
            {
              word: "get",
              start: 8.587212,
              end: 8.901827,
              confidence: 0.99902344,
              punctuated_word: "get",
            },
            {
              word: "some",
              start: 8.901827,
              end: 9.137789,
              confidence: 0.99316406,
              punctuated_word: "some",
            },
            {
              word: "interesting",
              start: 9.137789,
              end: 9.637789,
              confidence: 0.99609375,
              punctuated_word: "interesting",
            },
            {
              word: "results",
              start: 9.767019,
              end: 10.002981,
              confidence: 0.9921875,
              punctuated_word: "results",
            },
            {
              word: "for",
              start: 10.002981,
              end: 10.317596,
              confidence: 0.99560547,
              punctuated_word: "for",
            },
            {
              word: "testing",
              start: 10.317596,
              end: 10.632212,
              confidence: 1,
              punctuated_word: "testing.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0199995,
    start: 7.84,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "So we can get some interesting results for testing.",
          confidence: 0.99609375,
          words: [
            {
              word: "so",
              start: 8.038027,
              end: 8.196447,
              confidence: 0.99902344,
              punctuated_word: "So",
            },
            {
              word: "we",
              start: 8.196447,
              end: 8.354869,
              confidence: 0.99560547,
              punctuated_word: "we",
            },
            {
              word: "can",
              start: 8.354869,
              end: 8.5925,
              confidence: 0.99316406,
              punctuated_word: "can",
            },
            {
              word: "get",
              start: 8.5925,
              end: 8.909342,
              confidence: 0.99902344,
              punctuated_word: "get",
            },
            {
              word: "some",
              start: 8.909342,
              end: 9.146974,
              confidence: 0.9941406,
              punctuated_word: "some",
            },
            {
              word: "interesting",
              start: 9.146974,
              end: 9.646974,
              confidence: 0.9970703,
              punctuated_word: "interesting",
            },
            {
              word: "results",
              start: 9.780658,
              end: 10.01829,
              confidence: 0.9926758,
              punctuated_word: "results",
            },
            {
              word: "for",
              start: 10.01829,
              end: 10.335132,
              confidence: 0.99609375,
              punctuated_word: "for",
            },
            {
              word: "testing",
              start: 10.335132,
              end: 10.651974,
              confidence: 1,
              punctuated_word: "testing.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.039938,
    start: 10.86,
    is_final: false,
    speech_final: false,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0599375,
    start: 10.86,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "I gonna talk about",
          confidence: 0.5839844,
          words: [
            {
              word: "i",
              start: 13.167051,
              end: 13.323462,
              confidence: 0.46923828,
              punctuated_word: "I",
            },
            {
              word: "gonna",
              start: 13.323462,
              end: 13.558077,
              confidence: 0.45263672,
              punctuated_word: "gonna",
            },
            {
              word: "talk",
              start: 13.558077,
              end: 13.792692,
              confidence: 0.9746094,
              punctuated_word: "talk",
            },
            {
              word: "about",
              start: 13.792692,
              end: 13.919937,
              confidence: 0.5839844,
              punctuated_word: "about",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.079938,
    start: 10.86,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "I'm gonna talk about pet matching.",
          confidence: 0.96191406,
          words: [
            {
              word: "i'm",
              start: 13.214215,
              end: 13.373823,
              confidence: 0.6713867,
              punctuated_word: "I'm",
            },
            {
              word: "gonna",
              start: 13.373823,
              end: 13.613235,
              confidence: 0.74316406,
              punctuated_word: "gonna",
            },
            {
              word: "talk",
              start: 13.613235,
              end: 13.852647,
              confidence: 0.9995117,
              punctuated_word: "talk",
            },
            {
              word: "about",
              start: 13.852647,
              end: 14.092058,
              confidence: 0.9995117,
              punctuated_word: "about",
            },
            {
              word: "pet",
              start: 14.092058,
              end: 14.411274,
              confidence: 0.90722656,
              punctuated_word: "pet",
            },
            {
              word: "matching",
              start: 14.411274,
              end: 14.73049,
              confidence: 0.96191406,
              punctuated_word: "matching.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.1100006,
    start: 10.86,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript: "I'm gonna talk about pet matching.",
          confidence: 0.9614258,
          words: [
            {
              word: "i'm",
              start: 13.185961,
              end: 13.343654,
              confidence: 0.6723633,
              punctuated_word: "I'm",
            },
            {
              word: "gonna",
              start: 13.343654,
              end: 13.580192,
              confidence: 0.7314453,
              punctuated_word: "gonna",
            },
            {
              word: "talk",
              start: 13.580192,
              end: 13.8167305,
              confidence: 0.9995117,
              punctuated_word: "talk",
            },
            {
              word: "about",
              start: 13.8167305,
              end: 14.053268,
              confidence: 0.9995117,
              punctuated_word: "about",
            },
            {
              word: "pet",
              start: 14.053268,
              end: 14.368653,
              confidence: 0.91259766,
              punctuated_word: "pet",
            },
            {
              word: "matching",
              start: 14.368653,
              end: 14.684038,
              confidence: 0.9614258,
              punctuated_word: "matching.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0499372,
    start: 14.97,
    is_final: false,
    speech_final: false,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0699377,
    start: 14.97,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Matching method the allows developers",
          confidence: 0.5576172,
          words: [
            {
              word: "matching",
              start: 15.326539,
              end: 15.801924,
              confidence: 0.54833984,
              punctuated_word: "Matching",
            },
            {
              word: "method",
              start: 15.801924,
              end: 16.118847,
              confidence: 0.9160156,
              punctuated_word: "method",
            },
            {
              word: "the",
              start: 16.118847,
              end: 16.277308,
              confidence: 0.46606445,
              punctuated_word: "the",
            },
            {
              word: "allows",
              start: 16.277308,
              end: 16.59423,
              confidence: 0.5576172,
              punctuated_word: "allows",
            },
            {
              word: "developers",
              start: 16.59423,
              end: 17.039938,
              confidence: 0.6904297,
              punctuated_word: "developers",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0899382,
    start: 14.97,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "Pet matching is a method that allows developers to handle indirect",
          confidence: 0.9848633,
          words: [
            {
              word: "pet",
              start: 15.088462,
              end: 15.325385,
              confidence: 0.38671875,
              punctuated_word: "Pet",
            },
            {
              word: "matching",
              start: 15.325385,
              end: 15.562308,
              confidence: 0.8989258,
              punctuated_word: "matching",
            },
            {
              word: "is",
              start: 15.562308,
              end: 15.641282,
              confidence: 0.7651367,
              punctuated_word: "is",
            },
            {
              word: "a",
              start: 15.641282,
              end: 15.799231,
              confidence: 0.3395996,
              punctuated_word: "a",
            },
            {
              word: "method",
              start: 15.799231,
              end: 16.115128,
              confidence: 0.99658203,
              punctuated_word: "method",
            },
            {
              word: "that",
              start: 16.115128,
              end: 16.273077,
              confidence: 0.44580078,
              punctuated_word: "that",
            },
            {
              word: "allows",
              start: 16.273077,
              end: 16.588974,
              confidence: 0.9848633,
              punctuated_word: "allows",
            },
            {
              word: "developers",
              start: 16.588974,
              end: 16.983847,
              confidence: 0.99658203,
              punctuated_word: "developers",
            },
            {
              word: "to",
              start: 16.983847,
              end: 17.141794,
              confidence: 0.9921875,
              punctuated_word: "to",
            },
            {
              word: "handle",
              start: 17.141794,
              end: 17.641794,
              confidence: 0.99853516,
              punctuated_word: "handle",
            },
            {
              word: "indirect",
              start: 17.852564,
              end: 18.059938,
              confidence: 0.99316406,
              punctuated_word: "indirect",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.1099367,
    start: 14.97,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "Pattern matching is a method that allows developers to handle indirect conditions within",
          confidence: 0.96972656,
          words: [
            {
              word: "pattern",
              start: 15.088269,
              end: 15.324808,
              confidence: 0.5673828,
              punctuated_word: "Pattern",
            },
            {
              word: "matching",
              start: 15.324808,
              end: 15.561346,
              confidence: 0.9423828,
              punctuated_word: "matching",
            },
            {
              word: "is",
              start: 15.561346,
              end: 15.640192,
              confidence: 0.87158203,
              punctuated_word: "is",
            },
            {
              word: "a",
              start: 15.640192,
              end: 15.797885,
              confidence: 0.5004883,
              punctuated_word: "a",
            },
            {
              word: "method",
              start: 15.797885,
              end: 16.11327,
              confidence: 0.9951172,
              punctuated_word: "method",
            },
            {
              word: "that",
              start: 16.11327,
              end: 16.270962,
              confidence: 0.53515625,
              punctuated_word: "that",
            },
            {
              word: "allows",
              start: 16.270962,
              end: 16.586346,
              confidence: 0.96972656,
              punctuated_word: "allows",
            },
            {
              word: "developers",
              start: 16.586346,
              end: 16.980577,
              confidence: 0.98535156,
              punctuated_word: "developers",
            },
            {
              word: "to",
              start: 16.980577,
              end: 17.13827,
              confidence: 0.9741211,
              punctuated_word: "to",
            },
            {
              word: "handle",
              start: 17.13827,
              end: 17.63827,
              confidence: 0.9980469,
              punctuated_word: "handle",
            },
            {
              word: "indirect",
              start: 17.847885,
              end: 18.347885,
              confidence: 0.9868164,
              punctuated_word: "indirect",
            },
            {
              word: "conditions",
              start: 18.399807,
              end: 18.715193,
              confidence: 0.95996094,
              punctuated_word: "conditions",
            },
            {
              word: "within",
              start: 18.715193,
              end: 19.079937,
              confidence: 0.9941406,
              punctuated_word: "within",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.8000002,
    start: 14.97,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "Pattern matching is a method that allows developers to handle indirect conditions,",
          confidence: 0.98779297,
          words: [
            {
              word: "pattern",
              start: 15.088438,
              end: 15.325313,
              confidence: 0.39038086,
              punctuated_word: "Pattern",
            },
            {
              word: "matching",
              start: 15.325313,
              end: 15.562188,
              confidence: 0.9873047,
              punctuated_word: "matching",
            },
            {
              word: "is",
              start: 15.562188,
              end: 15.641146,
              confidence: 0.93310547,
              punctuated_word: "is",
            },
            {
              word: "a",
              start: 15.641146,
              end: 15.799063,
              confidence: 0.6611328,
              punctuated_word: "a",
            },
            {
              word: "method",
              start: 15.799063,
              end: 16.114897,
              confidence: 0.99658203,
              punctuated_word: "method",
            },
            {
              word: "that",
              start: 16.114897,
              end: 16.272812,
              confidence: 0.6972656,
              punctuated_word: "that",
            },
            {
              word: "allows",
              start: 16.272812,
              end: 16.588646,
              confidence: 0.99316406,
              punctuated_word: "allows",
            },
            {
              word: "developers",
              start: 16.588646,
              end: 16.983438,
              confidence: 0.99853516,
              punctuated_word: "developers",
            },
            {
              word: "to",
              start: 16.983438,
              end: 17.141354,
              confidence: 0.99609375,
              punctuated_word: "to",
            },
            {
              word: "handle",
              start: 17.141354,
              end: 17.641354,
              confidence: 0.9995117,
              punctuated_word: "handle",
            },
            {
              word: "indirect",
              start: 17.85198,
              end: 18.35198,
              confidence: 0.98779297,
              punctuated_word: "indirect",
            },
            {
              word: "conditions",
              start: 18.404688,
              end: 18.562605,
              confidence: 0.9770508,
              punctuated_word: "conditions,",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.2099361,
    start: 18.77,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "within a single",
          confidence: 0.9379883,
          words: [
            {
              word: "within",
              start: 18.890001,
              end: 19.050001,
              confidence: 0.9194336,
              punctuated_word: "within",
            },
            {
              word: "a",
              start: 19.050001,
              end: 19.29,
              confidence: 0.9379883,
              punctuated_word: "a",
            },
            {
              word: "single",
              start: 19.29,
              end: 19.79,
              confidence: 0.9663086,
              punctuated_word: "single",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.8199997,
    start: 18.77,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript: "within a single concise expression.",
          confidence: 0.9892578,
          words: [
            {
              word: "within",
              start: 18.888044,
              end: 19.045435,
              confidence: 0.8120117,
              punctuated_word: "within",
            },
            {
              word: "a",
              start: 19.045435,
              end: 19.281523,
              confidence: 0.9892578,
              punctuated_word: "a",
            },
            {
              word: "single",
              start: 19.281523,
              end: 19.753696,
              confidence: 0.9970703,
              punctuated_word: "single",
            },
            {
              word: "concise",
              start: 19.753696,
              end: 20.068478,
              confidence: 0.9995117,
              punctuated_word: "concise",
            },
            {
              word: "expression",
              start: 20.068478,
              end: 20.38326,
              confidence: 0.9760742,
              punctuated_word: "expression.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0099373,
    start: 20.59,
    is_final: false,
    speech_final: false,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0299377,
    start: 20.59,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "It's court, may",
          confidence: 0.9038086,
          words: [
            {
              word: "it's",
              start: 21.172693,
              end: 21.672693,
              confidence: 0.9038086,
              punctuated_word: "It's",
            },
            {
              word: "court",
              start: 21.871923,
              end: 22.105,
              confidence: 0.97509766,
              punctuated_word: "court,",
            },
            {
              word: "may",
              start: 22.493462,
              end: 22.619938,
              confidence: 0.4111328,
              punctuated_word: "may",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0499382,
    start: 20.59,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "It's in interface court, making more concise",
          confidence: 0.9536133,
          words: [
            {
              word: "it's",
              start: 21.19,
              end: 21.35,
              confidence: 0.9536133,
              punctuated_word: "It's",
            },
            {
              word: "in",
              start: 21.35,
              end: 21.67,
              confidence: 0.8383789,
              punctuated_word: "in",
            },
            {
              word: "interface",
              start: 21.67,
              end: 21.91,
              confidence: 0.37670898,
              punctuated_word: "interface",
            },
            {
              word: "court",
              start: 21.91,
              end: 22.07,
              confidence: 0.85009766,
              punctuated_word: "court,",
            },
            {
              word: "making",
              start: 22.55,
              end: 22.95,
              confidence: 0.9975586,
              punctuated_word: "making",
            },
            {
              word: "more",
              start: 22.95,
              end: 23.27,
              confidence: 0.99853516,
              punctuated_word: "more",
            },
            {
              word: "concise",
              start: 23.27,
              end: 23.639938,
              confidence: 1,
              punctuated_word: "concise",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.0699368,
    start: 20.59,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "It's in interface court, making more concise and easy to understand.",
          confidence: 0.98535156,
          words: [
            {
              word: "it's",
              start: 21.18706,
              end: 21.346275,
              confidence: 0.96533203,
              punctuated_word: "It's",
            },
            {
              word: "in",
              start: 21.346275,
              end: 21.664705,
              confidence: 0.9482422,
              punctuated_word: "in",
            },
            {
              word: "interface",
              start: 21.664705,
              end: 21.90353,
              confidence: 0.38183594,
              punctuated_word: "interface",
            },
            {
              word: "court",
              start: 21.90353,
              end: 22.142353,
              confidence: 0.37231445,
              punctuated_word: "court,",
            },
            {
              word: "making",
              start: 22.540392,
              end: 22.858824,
              confidence: 0.9975586,
              punctuated_word: "making",
            },
            {
              word: "more",
              start: 22.858824,
              end: 23.256863,
              confidence: 0.99902344,
              punctuated_word: "more",
            },
            {
              word: "concise",
              start: 23.256863,
              end: 23.575294,
              confidence: 1,
              punctuated_word: "concise",
            },
            {
              word: "and",
              start: 23.575294,
              end: 23.814117,
              confidence: 0.98535156,
              punctuated_word: "and",
            },
            {
              word: "easy",
              start: 23.814117,
              end: 24.05294,
              confidence: 0.859375,
              punctuated_word: "easy",
            },
            {
              word: "to",
              start: 24.05294,
              end: 24.371372,
              confidence: 0.99902344,
              punctuated_word: "to",
            },
            {
              word: "understand",
              start: 24.371372,
              end: 24.530588,
              confidence: 0.99560547,
              punctuated_word: "understand.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.9699993,
    start: 20.59,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "It's in interface court, making more concise and easy to understand. Additionally,",
          confidence: 0.9946289,
          words: [
            {
              word: "it's",
              start: 21.19,
              end: 21.35,
              confidence: 0.9433594,
              punctuated_word: "It's",
            },
            {
              word: "in",
              start: 21.35,
              end: 21.67,
              confidence: 0.93896484,
              punctuated_word: "in",
            },
            {
              word: "interface",
              start: 21.67,
              end: 21.91,
              confidence: 0.5703125,
              punctuated_word: "interface",
            },
            {
              word: "court",
              start: 21.91,
              end: 22.15,
              confidence: 0.45703125,
              punctuated_word: "court,",
            },
            {
              word: "making",
              start: 22.55,
              end: 22.95,
              confidence: 0.99853516,
              punctuated_word: "making",
            },
            {
              word: "more",
              start: 22.95,
              end: 23.27,
              confidence: 0.9995117,
              punctuated_word: "more",
            },
            {
              word: "concise",
              start: 23.27,
              end: 23.59,
              confidence: 1,
              punctuated_word: "concise",
            },
            {
              word: "and",
              start: 23.59,
              end: 23.83,
              confidence: 0.97558594,
              punctuated_word: "and",
            },
            {
              word: "easy",
              start: 23.83,
              end: 24.07,
              confidence: 0.85546875,
              punctuated_word: "easy",
            },
            {
              word: "to",
              start: 24.07,
              end: 24.39,
              confidence: 0.9995117,
              punctuated_word: "to",
            },
            {
              word: "understand",
              start: 24.39,
              end: 24.55,
              confidence: 0.9946289,
              punctuated_word: "understand.",
            },
            {
              word: "additionally",
              start: 24.95,
              end: 25.35,
              confidence: 0.9995117,
              punctuated_word: "Additionally,",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0199375,
    start: 25.56,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "includes a process",
          confidence: 0.99072266,
          words: [
            {
              word: "includes",
              start: 25.831923,
              end: 26.064999,
              confidence: 0.99072266,
              punctuated_word: "includes",
            },
            {
              word: "a",
              start: 26.064999,
              end: 26.220385,
              confidence: 0.83251953,
              punctuated_word: "a",
            },
            {
              word: "process",
              start: 26.220385,
              end: 26.579937,
              confidence: 0.9941406,
              punctuated_word: "process",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.8199997,
    start: 25.56,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript: "it includes a process called access.",
          confidence: 0.9980469,
          words: [
            {
              word: "it",
              start: 25.599348,
              end: 25.835434,
              confidence: 0.94970703,
              punctuated_word: "it",
            },
            {
              word: "includes",
              start: 25.835434,
              end: 26.071522,
              confidence: 0.9980469,
              punctuated_word: "includes",
            },
            {
              word: "a",
              start: 26.071522,
              end: 26.228912,
              confidence: 0.94433594,
              punctuated_word: "a",
            },
            {
              word: "process",
              start: 26.228912,
              end: 26.62239,
              confidence: 0.99853516,
              punctuated_word: "process",
            },
            {
              word: "called",
              start: 26.62239,
              end: 26.937174,
              confidence: 0.9980469,
              punctuated_word: "called",
            },
            {
              word: "access",
              start: 26.937174,
              end: 27.17326,
              confidence: 0.46923828,
              punctuated_word: "access.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0599384,
    start: 27.38,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Exhaustive check",
          confidence: 0.9980469,
          words: [
            {
              word: "exhaustive",
              start: 27.867498,
              end: 28.242498,
              confidence: 0.9980469,
              punctuated_word: "Exhaustive",
            },
            {
              word: "check",
              start: 28.242498,
              end: 28.439938,
              confidence: 0.9116211,
              punctuated_word: "check",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.079939,
    start: 27.38,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Exhaustive checking, which helps to",
          confidence: 0.96875,
          words: [
            {
              word: "exhaustive",
              start: 27.8975,
              end: 28.295576,
              confidence: 0.9975586,
              punctuated_word: "Exhaustive",
            },
            {
              word: "checking",
              start: 28.295576,
              end: 28.614038,
              confidence: 0.98828125,
              punctuated_word: "checking,",
            },
            {
              word: "which",
              start: 28.932499,
              end: 29.09173,
              confidence: 0.96875,
              punctuated_word: "which",
            },
            {
              word: "helps",
              start: 29.09173,
              end: 29.330576,
              confidence: 0.39257812,
              punctuated_word: "helps",
            },
            {
              word: "to",
              start: 29.330576,
              end: 29.459938,
              confidence: 0.5395508,
              punctuated_word: "to",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0999374,
    start: 27.38,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Exhaustive checking, which helps to ensure that no pos",
          confidence: 0.9951172,
          words: [
            {
              word: "exhaustive",
              start: 27.894999,
              end: 28.291153,
              confidence: 0.9951172,
              punctuated_word: "Exhaustive",
            },
            {
              word: "checking",
              start: 28.291153,
              end: 28.608076,
              confidence: 0.9951172,
              punctuated_word: "checking,",
            },
            {
              word: "which",
              start: 28.845768,
              end: 29.08346,
              confidence: 0.9980469,
              punctuated_word: "which",
            },
            {
              word: "helps",
              start: 29.08346,
              end: 29.321154,
              confidence: 0.88134766,
              punctuated_word: "helps",
            },
            {
              word: "to",
              start: 29.321154,
              end: 29.558846,
              confidence: 0.99853516,
              punctuated_word: "to",
            },
            {
              word: "ensure",
              start: 29.558846,
              end: 29.717306,
              confidence: 1,
              punctuated_word: "ensure",
            },
            {
              word: "that",
              start: 29.717306,
              end: 29.955,
              confidence: 0.9926758,
              punctuated_word: "that",
            },
            {
              word: "no",
              start: 29.955,
              end: 30.271923,
              confidence: 0.5649414,
              punctuated_word: "no",
            },
            {
              word: "pos",
              start: 30.271923,
              end: 30.479937,
              confidence: 0.1685791,
              punctuated_word: "pos",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.040001,
    start: 27.38,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript:
            "Exhaustive checking, which helps to ensure that no possible case can",
          confidence: 0.99658203,
          words: [
            {
              word: "exhaustive",
              start: 27.893627,
              end: 28.288725,
              confidence: 0.99609375,
              punctuated_word: "Exhaustive",
            },
            {
              word: "checking",
              start: 28.288725,
              end: 28.604803,
              confidence: 0.9975586,
              punctuated_word: "checking,",
            },
            {
              word: "which",
              start: 28.920881,
              end: 29.07892,
              confidence: 0.99853516,
              punctuated_word: "which",
            },
            {
              word: "helps",
              start: 29.07892,
              end: 29.315979,
              confidence: 0.7885742,
              punctuated_word: "helps",
            },
            {
              word: "to",
              start: 29.315979,
              end: 29.553038,
              confidence: 0.9995117,
              punctuated_word: "to",
            },
            {
              word: "ensure",
              start: 29.553038,
              end: 29.711077,
              confidence: 1,
              punctuated_word: "ensure",
            },
            {
              word: "that",
              start: 29.711077,
              end: 29.948135,
              confidence: 0.99658203,
              punctuated_word: "that",
            },
            {
              word: "no",
              start: 29.948135,
              end: 30.264215,
              confidence: 0.90625,
              punctuated_word: "no",
            },
            {
              word: "possible",
              start: 30.264215,
              end: 30.659313,
              confidence: 0.99902344,
              punctuated_word: "possible",
            },
            {
              word: "case",
              start: 30.659313,
              end: 30.975391,
              confidence: 0.96875,
              punctuated_word: "case",
            },
            {
              word: "can",
              start: 30.975391,
              end: 31.42,
              confidence: 0.99609375,
              punctuated_word: "can",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.039938,
    start: 31.42,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "has been overlooked",
          confidence: 0.9975586,
          words: [
            {
              word: "has",
              start: 31.538847,
              end: 31.776539,
              confidence: 0.9975586,
              punctuated_word: "has",
            },
            {
              word: "been",
              start: 31.776539,
              end: 32.17269,
              confidence: 0.9941406,
              punctuated_word: "been",
            },
            {
              word: "overlooked",
              start: 32.17269,
              end: 32.459938,
              confidence: 0.9995117,
              punctuated_word: "overlooked",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0799999,
    start: 31.42,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript: "has been overlooked.",
          confidence: 0.99853516,
          words: [
            {
              word: "has",
              start: 31.534643,
              end: 31.76393,
              confidence: 0.99853516,
              punctuated_word: "has",
            },
            {
              word: "been",
              start: 31.76393,
              end: 32.146072,
              confidence: 0.9970703,
              punctuated_word: "been",
            },
            {
              word: "overlooked",
              start: 32.146072,
              end: 32.298927,
              confidence: 1,
              punctuated_word: "overlooked.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0399361,
    start: 32.5,
    is_final: false,
    speech_final: false,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0599365,
    start: 32.5,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Originating from factor",
          confidence: 0.9511719,
          words: [
            {
              word: "originating",
              start: 33.485577,
              end: 33.879807,
              confidence: 0.99658203,
              punctuated_word: "Originating",
            },
            {
              word: "from",
              start: 33.879807,
              end: 34.27404,
              confidence: 0.9511719,
              punctuated_word: "from",
            },
            {
              word: "factor",
              start: 34.27404,
              end: 34.559937,
              confidence: 0.62402344,
              punctuated_word: "factor",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.079937,
    start: 32.5,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Originating from program languages",
          confidence: 0.99853516,
          words: [
            {
              word: "originating",
              start: 33.483974,
              end: 33.877563,
              confidence: 1,
              punctuated_word: "Originating",
            },
            {
              word: "from",
              start: 33.877563,
              end: 34.377563,
              confidence: 0.99609375,
              punctuated_word: "from",
            },
            {
              word: "program",
              start: 34.66474,
              end: 35.058334,
              confidence: 0.87939453,
              punctuated_word: "program",
            },
            {
              word: "languages",
              start: 35.058334,
              end: 35.558334,
              confidence: 0.99853516,
              punctuated_word: "languages",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.0999374,
    start: 32.5,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "Originating from functional program languages, pattern matching",
          confidence: 0.98828125,
          words: [
            {
              word: "originating",
              start: 33.56183,
              end: 33.876442,
              confidence: 0.9995117,
              punctuated_word: "Originating",
            },
            {
              word: "from",
              start: 33.876442,
              end: 34.376442,
              confidence: 0.9946289,
              punctuated_word: "from",
            },
            {
              word: "functional",
              start: 34.427017,
              end: 34.66298,
              confidence: 0.50390625,
              punctuated_word: "functional",
            },
            {
              word: "program",
              start: 34.66298,
              end: 35.05625,
              confidence: 0.66845703,
              punctuated_word: "program",
            },
            {
              word: "languages",
              start: 35.05625,
              end: 35.44952,
              confidence: 0.9995117,
              punctuated_word: "languages,",
            },
            {
              word: "pattern",
              start: 35.84279,
              end: 36.236057,
              confidence: 0.41870117,
              punctuated_word: "pattern",
            },
            {
              word: "matching",
              start: 36.236057,
              end: 36.599937,
              confidence: 0.98828125,
              punctuated_word: "matching",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.2299995,
    start: 32.5,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Originating from program languages,",
          confidence: 0.99902344,
          words: [
            {
              word: "originating",
              start: 33.560246,
              end: 33.87439,
              confidence: 0.99902344,
              punctuated_word: "Originating",
            },
            {
              word: "from",
              start: 33.87439,
              end: 34.37439,
              confidence: 0.9970703,
              punctuated_word: "from",
            },
            {
              word: "program",
              start: 34.659756,
              end: 35.05244,
              confidence: 0.82177734,
              punctuated_word: "program",
            },
            {
              word: "languages",
              start: 35.05244,
              end: 35.44512,
              confidence: 1,
              punctuated_word: "languages,",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.829937,
    start: 35.73,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "pet matching stands as a powerful",
          confidence: 0.95947266,
          words: [
            {
              word: "pet",
              start: 35.927826,
              end: 36.244347,
              confidence: 0.6430664,
              punctuated_word: "pet",
            },
            {
              word: "matching",
              start: 36.244347,
              end: 36.71913,
              confidence: 0.9785156,
              punctuated_word: "matching",
            },
            {
              word: "stands",
              start: 36.71913,
              end: 36.95652,
              confidence: 0.5644531,
              punctuated_word: "stands",
            },
            {
              word: "as",
              start: 36.95652,
              end: 37.035652,
              confidence: 0.7993164,
              punctuated_word: "as",
            },
            {
              word: "a",
              start: 37.035652,
              end: 37.273045,
              confidence: 0.95947266,
              punctuated_word: "a",
            },
            {
              word: "powerful",
              start: 37.273045,
              end: 37.559937,
              confidence: 0.9995117,
              punctuated_word: "powerful",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.8499374,
    start: 35.73,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "pet matching stance is a powerful technique for core",
          confidence: 0.99316406,
          words: [
            {
              word: "pet",
              start: 35.927223,
              end: 36.24278,
              confidence: 0.7128906,
              punctuated_word: "pet",
            },
            {
              word: "matching",
              start: 36.24278,
              end: 36.71611,
              confidence: 0.99560547,
              punctuated_word: "matching",
            },
            {
              word: "stance",
              start: 36.71611,
              end: 36.952778,
              confidence: 0.51953125,
              punctuated_word: "stance",
            },
            {
              word: "is",
              start: 36.952778,
              end: 37.031666,
              confidence: 0.7060547,
              punctuated_word: "is",
            },
            {
              word: "a",
              start: 37.031666,
              end: 37.268333,
              confidence: 0.9970703,
              punctuated_word: "a",
            },
            {
              word: "powerful",
              start: 37.268333,
              end: 37.768333,
              confidence: 0.9995117,
              punctuated_word: "powerful",
            },
            {
              word: "technique",
              start: 37.978333,
              end: 38.215,
              confidence: 1,
              punctuated_word: "technique",
            },
            {
              word: "for",
              start: 38.215,
              end: 38.451668,
              confidence: 0.99316406,
              punctuated_word: "for",
            },
            {
              word: "core",
              start: 38.451668,
              end: 38.579937,
              confidence: 0.8149414,
              punctuated_word: "core",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.4500008,
    start: 35.73,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript:
            "Pet matching stance is a powerful technique for chord branching.",
          confidence: 0.9814453,
          words: [
            {
              word: "pet",
              start: 35.93,
              end: 36.25,
              confidence: 0.7963867,
              punctuated_word: "Pet",
            },
            {
              word: "matching",
              start: 36.25,
              end: 36.73,
              confidence: 0.9814453,
              punctuated_word: "matching",
            },
            {
              word: "stance",
              start: 36.73,
              end: 36.97,
              confidence: 0.5957031,
              punctuated_word: "stance",
            },
            {
              word: "is",
              start: 36.97,
              end: 37.05,
              confidence: 0.5341797,
              punctuated_word: "is",
            },
            {
              word: "a",
              start: 37.05,
              end: 37.29,
              confidence: 0.9980469,
              punctuated_word: "a",
            },
            {
              word: "powerful",
              start: 37.29,
              end: 37.79,
              confidence: 1,
              punctuated_word: "powerful",
            },
            {
              word: "technique",
              start: 38.01,
              end: 38.25,
              confidence: 1,
              punctuated_word: "technique",
            },
            {
              word: "for",
              start: 38.25,
              end: 38.489998,
              confidence: 0.9995117,
              punctuated_word: "for",
            },
            {
              word: "chord",
              start: 38.489998,
              end: 38.73,
              confidence: 0.34301758,
              punctuated_word: "chord",
            },
            {
              word: "branching",
              start: 38.73,
              end: 38.97,
              confidence: 0.9814453,
              punctuated_word: "branching.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0199356,
    start: 39.18,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "It off",
          confidence: 0.95996094,
          words: [
            {
              word: "it",
              start: 39.840385,
              end: 39.99577,
              confidence: 0.95996094,
              punctuated_word: "It",
            },
            {
              word: "off",
              start: 39.99577,
              end: 40.199936,
              confidence: 0.6972656,
              punctuated_word: "off",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.039936,
    start: 39.18,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "It offers them more.",
          confidence: 0.9926758,
          words: [
            {
              word: "it",
              start: 39.843655,
              end: 39.99981,
              confidence: 0.9926758,
              punctuated_word: "It",
            },
            {
              word: "offers",
              start: 39.99981,
              end: 40.312115,
              confidence: 0.9970703,
              punctuated_word: "offers",
            },
            {
              word: "them",
              start: 40.312115,
              end: 40.546345,
              confidence: 0.43847656,
              punctuated_word: "them",
            },
            {
              word: "more",
              start: 40.546345,
              end: 40.78058,
              confidence: 0.99121094,
              punctuated_word: "more.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0599365,
    start: 39.18,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "It offers more Bolton",
          confidence: 0.99609375,
          words: [
            {
              word: "it",
              start: 39.844746,
              end: 40.001156,
              confidence: 0.99609375,
              punctuated_word: "It",
            },
            {
              word: "offers",
              start: 40.001156,
              end: 40.501156,
              confidence: 0.9980469,
              punctuated_word: "offers",
            },
            {
              word: "more",
              start: 40.54859,
              end: 41.04859,
              confidence: 0.92871094,
              punctuated_word: "more",
            },
            {
              word: "bolton",
              start: 41.72167,
              end: 42.22167,
              confidence: 0.65625,
              punctuated_word: "Bolton",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.079937,
    start: 39.18,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "It offers them more. Potent and less for both solution",
          confidence: 0.9379883,
          words: [
            {
              word: "it",
              start: 39.858334,
              end: 40.01794,
              confidence: 0.99658203,
              punctuated_word: "It",
            },
            {
              word: "offers",
              start: 40.01794,
              end: 40.33716,
              confidence: 0.99902344,
              punctuated_word: "offers",
            },
            {
              word: "them",
              start: 40.33716,
              end: 40.57657,
              confidence: 0.47265625,
              punctuated_word: "them",
            },
            {
              word: "more",
              start: 40.57657,
              end: 40.81598,
              confidence: 0.97802734,
              punctuated_word: "more.",
            },
            {
              word: "potent",
              start: 41.77363,
              end: 42.172646,
              confidence: 0.7626953,
              punctuated_word: "Potent",
            },
            {
              word: "and",
              start: 42.172646,
              end: 42.41206,
              confidence: 0.95703125,
              punctuated_word: "and",
            },
            {
              word: "less",
              start: 42.41206,
              end: 42.571667,
              confidence: 0.8232422,
              punctuated_word: "less",
            },
            {
              word: "for",
              start: 42.571667,
              end: 42.731274,
              confidence: 0.45141602,
              punctuated_word: "for",
            },
            {
              word: "both",
              start: 42.731274,
              end: 43.130295,
              confidence: 0.9379883,
              punctuated_word: "both",
            },
            {
              word: "solution",
              start: 43.130295,
              end: 43.259937,
              confidence: 0.8623047,
              punctuated_word: "solution",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.549999,
    start: 39.18,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "It offers them more. Potent and less for both solution compared",
          confidence: 0.9716797,
          words: [
            {
              word: "it",
              start: 39.857018,
              end: 40.016315,
              confidence: 0.9980469,
              punctuated_word: "It",
            },
            {
              word: "offers",
              start: 40.016315,
              end: 40.33491,
              confidence: 0.99902344,
              punctuated_word: "offers",
            },
            {
              word: "them",
              start: 40.33491,
              end: 40.57386,
              confidence: 0.6308594,
              punctuated_word: "them",
            },
            {
              word: "more",
              start: 40.57386,
              end: 40.81281,
              confidence: 0.98828125,
              punctuated_word: "more.",
            },
            {
              word: "potent",
              start: 41.768597,
              end: 42.166843,
              confidence: 0.8574219,
              punctuated_word: "Potent",
            },
            {
              word: "and",
              start: 42.166843,
              end: 42.40579,
              confidence: 0.9716797,
              punctuated_word: "and",
            },
            {
              word: "less",
              start: 42.40579,
              end: 42.565086,
              confidence: 0.8676758,
              punctuated_word: "less",
            },
            {
              word: "for",
              start: 42.565086,
              end: 42.72439,
              confidence: 0.50634766,
              punctuated_word: "for",
            },
            {
              word: "both",
              start: 42.72439,
              end: 43.12263,
              confidence: 0.96728516,
              punctuated_word: "both",
            },
            {
              word: "solution",
              start: 43.12263,
              end: 43.520878,
              confidence: 0.98828125,
              punctuated_word: "solution",
            },
            {
              word: "compared",
              start: 43.520878,
              end: 43.73,
              confidence: 0.99853516,
              punctuated_word: "compared",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0299377,
    start: 43.73,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "imperative,",
          confidence: 0.9555664,
          words: [
            {
              word: "imperative",
              start: 44.004616,
              end: 44.504616,
              confidence: 0.9555664,
              punctuated_word: "imperative,",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0499382,
    start: 43.73,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "imperative alternatives, such as",
          confidence: 0.9941406,
          words: [
            {
              word: "imperative",
              start: 44.004616,
              end: 44.504616,
              confidence: 0.9926758,
              punctuated_word: "imperative",
            },
            {
              word: "alternatives",
              start: 44.553844,
              end: 44.94615,
              confidence: 0.93066406,
              punctuated_word: "alternatives,",
            },
            {
              word: "such",
              start: 45.416924,
              end: 45.573845,
              confidence: 1,
              punctuated_word: "such",
            },
            {
              word: "as",
              start: 45.573845,
              end: 45.779938,
              confidence: 0.9941406,
              punctuated_word: "as",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.8600006,
    start: 43.73,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript: "imperative alternatives, such as,",
          confidence: 0.99609375,
          words: [
            {
              word: "imperative",
              start: 44.007084,
              end: 44.507084,
              confidence: 0.9926758,
              punctuated_word: "imperative",
            },
            {
              word: "alternatives",
              start: 44.56125,
              end: 44.957085,
              confidence: 0.9794922,
              punctuated_word: "alternatives,",
            },
            {
              word: "such",
              start: 45.432083,
              end: 45.590416,
              confidence: 0.9995117,
              punctuated_word: "such",
            },
            {
              word: "as",
              start: 45.590416,
              end: 45.74875,
              confidence: 0.99609375,
              punctuated_word: "as,",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0499382,
    start: 46.59,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "or switch statements",
          confidence: 0.8823242,
          words: [
            {
              word: "or",
              start: 46.63,
              end: 46.87,
              confidence: 0.9301758,
              punctuated_word: "or",
            },
            {
              word: "switch",
              start: 46.87,
              end: 47.19,
              confidence: 0.66845703,
              punctuated_word: "switch",
            },
            {
              word: "statements",
              start: 47.19,
              end: 47.63994,
              confidence: 0.8823242,
              punctuated_word: "statements",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0699387,
    start: 46.59,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "or statements particularly",
          confidence: 0.9604492,
          words: [
            {
              word: "or",
              start: 46.629616,
              end: 47.129616,
              confidence: 0.4711914,
              punctuated_word: "or",
            },
            {
              word: "statements",
              start: 47.18423,
              end: 47.68423,
              confidence: 0.9711914,
              punctuated_word: "statements",
            },
            {
              word: "particularly",
              start: 47.89731,
              end: 48.39731,
              confidence: 0.9604492,
              punctuated_word: "particularly",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.089939,
    start: 46.59,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "switch statements, particularly when dealing of complex cost",
          confidence: 0.96435547,
          words: [
            {
              word: "switch",
              start: 46.86641,
              end: 47.18231,
              confidence: 0.63183594,
              punctuated_word: "switch",
            },
            {
              word: "statements",
              start: 47.18231,
              end: 47.57718,
              confidence: 0.9848633,
              punctuated_word: "statements,",
            },
            {
              word: "particularly",
              start: 47.893078,
              end: 48.393078,
              confidence: 0.99902344,
              punctuated_word: "particularly",
            },
            {
              word: "when",
              start: 48.52487,
              end: 48.761795,
              confidence: 0.9863281,
              punctuated_word: "when",
            },
            {
              word: "dealing",
              start: 48.761795,
              end: 48.99872,
              confidence: 0.68603516,
              punctuated_word: "dealing",
            },
            {
              word: "of",
              start: 48.99872,
              end: 49.23564,
              confidence: 0.28881836,
              punctuated_word: "of",
            },
            {
              word: "complex",
              start: 49.23564,
              end: 49.630512,
              confidence: 0.96435547,
              punctuated_word: "complex",
            },
            {
              word: "cost",
              start: 49.630512,
              end: 49.67994,
              confidence: 0.6254883,
              punctuated_word: "cost",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.7000008,
    start: 46.59,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript:
            "or switch statements, particularly when dealing with complex conditions.",
          confidence: 0.98535156,
          words: [
            {
              word: "or",
              start: 46.707767,
              end: 46.864788,
              confidence: 0.4230957,
              punctuated_word: "or",
            },
            {
              word: "switch",
              start: 46.864788,
              end: 47.17883,
              confidence: 0.4177246,
              punctuated_word: "switch",
            },
            {
              word: "statements",
              start: 47.17883,
              end: 47.571384,
              confidence: 0.98535156,
              punctuated_word: "statements,",
            },
            {
              word: "particularly",
              start: 47.885426,
              end: 48.385426,
              confidence: 0.9995117,
              punctuated_word: "particularly",
            },
            {
              word: "when",
              start: 48.51351,
              end: 48.749043,
              confidence: 0.9975586,
              punctuated_word: "when",
            },
            {
              word: "dealing",
              start: 48.749043,
              end: 48.984573,
              confidence: 0.99316406,
              punctuated_word: "dealing",
            },
            {
              word: "with",
              start: 48.984573,
              end: 49.220108,
              confidence: 0.93652344,
              punctuated_word: "with",
            },
            {
              word: "complex",
              start: 49.220108,
              end: 49.720108,
              confidence: 1,
              punctuated_word: "complex",
            },
            {
              word: "conditions",
              start: 49.84819,
              end: 50.00521,
              confidence: 0.9453125,
              punctuated_word: "conditions.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0099373,
    start: 50.29,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Although",
          confidence: 0.78808594,
          words: [
            {
              word: "although",
              start: 50.636154,
              end: 51.136154,
              confidence: 0.78808594,
              punctuated_word: "Although",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.829998,
    start: 50.29,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript: "Although not yet a native.",
          confidence: 0.99902344,
          words: [
            {
              word: "although",
              start: 50.646088,
              end: 51.04174,
              confidence: 0.99902344,
              punctuated_word: "Although",
            },
            {
              word: "not",
              start: 51.04174,
              end: 51.2,
              confidence: 1,
              punctuated_word: "not",
            },
            {
              word: "yet",
              start: 51.2,
              end: 51.437393,
              confidence: 0.9995117,
              punctuated_word: "yet",
            },
            {
              word: "a",
              start: 51.437393,
              end: 51.595654,
              confidence: 0.96875,
              punctuated_word: "a",
            },
            {
              word: "native",
              start: 51.595654,
              end: 51.912174,
              confidence: 0.9946289,
              punctuated_word: "native.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 1.0399399,
    start: 52.12,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Feature javascript,",
          confidence: 0.9609375,
          words: [
            {
              word: "feature",
              start: 52.39731,
              end: 52.635,
              confidence: 0.5161133,
              punctuated_word: "Feature",
            },
            {
              word: "javascript",
              start: 52.635,
              end: 52.951923,
              confidence: 0.9609375,
              punctuated_word: "javascript,",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.0599403,
    start: 52.12,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Feature Javascript. There's an ongoing",
          confidence: 0.9902344,
          words: [
            {
              word: "feature",
              start: 52.39596,
              end: 52.553654,
              confidence: 0.56689453,
              punctuated_word: "Feature",
            },
            {
              word: "javascript",
              start: 52.6325,
              end: 53.02673,
              confidence: 0.67871094,
              punctuated_word: "Javascript.",
            },
            {
              word: "there's",
              start: 53.42096,
              end: 53.57865,
              confidence: 0.9902344,
              punctuated_word: "There's",
            },
            {
              word: "an",
              start: 53.57865,
              end: 53.972885,
              confidence: 0.99853516,
              punctuated_word: "an",
            },
            {
              word: "ongoing",
              start: 53.972885,
              end: 54.17994,
              confidence: 0.99902344,
              punctuated_word: "ongoing",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.079937,
    start: 52.12,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Feature Javascript. There's an ongoing Tc thirty nine",
          confidence: 0.97509766,
          words: [
            {
              word: "feature",
              start: 52.39551,
              end: 52.552948,
              confidence: 0.5253906,
              punctuated_word: "Feature",
            },
            {
              word: "javascript",
              start: 52.710384,
              end: 53.025257,
              confidence: 0.8642578,
              punctuated_word: "Javascript.",
            },
            {
              word: "there's",
              start: 53.418846,
              end: 53.576283,
              confidence: 0.97509766,
              punctuated_word: "There's",
            },
            {
              word: "an",
              start: 53.576283,
              end: 53.96987,
              confidence: 0.99658203,
              punctuated_word: "an",
            },
            {
              word: "ongoing",
              start: 53.96987,
              end: 54.127308,
              confidence: 0.98828125,
              punctuated_word: "ongoing",
            },
            {
              word: "tc",
              start: 54.284744,
              end: 54.520897,
              confidence: 0.92529297,
              punctuated_word: "Tc",
            },
            {
              word: "thirty",
              start: 54.520897,
              end: 54.83577,
              confidence: 0.52441406,
              punctuated_word: "thirty",
            },
            {
              word: "nine",
              start: 54.83577,
              end: 55.199936,
              confidence: 0.9946289,
              punctuated_word: "nine",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 4.0999374,
    start: 52.12,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "Feature Javascript. There's an ongoing Tc thirty nine proposal in its early",
          confidence: 0.9584961,
          words: [
            {
              word: "feature",
              start: 52.395287,
              end: 52.552593,
              confidence: 0.5859375,
              punctuated_word: "Feature",
            },
            {
              word: "javascript",
              start: 52.63125,
              end: 53.024517,
              confidence: 0.9248047,
              punctuated_word: "Javascript.",
            },
            {
              word: "there's",
              start: 53.417786,
              end: 53.575096,
              confidence: 0.9584961,
              punctuated_word: "There's",
            },
            {
              word: "an",
              start: 53.575096,
              end: 53.968365,
              confidence: 0.9946289,
              punctuated_word: "an",
            },
            {
              word: "ongoing",
              start: 53.968365,
              end: 54.12567,
              confidence: 0.9970703,
              punctuated_word: "ongoing",
            },
            {
              word: "tc",
              start: 54.282978,
              end: 54.597595,
              confidence: 0.94091797,
              punctuated_word: "Tc",
            },
            {
              word: "thirty",
              start: 54.597595,
              end: 54.833557,
              confidence: 0.6821289,
              punctuated_word: "thirty",
            },
            {
              word: "nine",
              start: 54.833557,
              end: 55.14817,
              confidence: 0.9824219,
              punctuated_word: "nine",
            },
            {
              word: "proposal",
              start: 55.14817,
              end: 55.462788,
              confidence: 0.99609375,
              punctuated_word: "proposal",
            },
            {
              word: "in",
              start: 55.462788,
              end: 55.620094,
              confidence: 0.98583984,
              punctuated_word: "in",
            },
            {
              word: "its",
              start: 55.620094,
              end: 55.856056,
              confidence: 0.9086914,
              punctuated_word: "its",
            },
            {
              word: "early",
              start: 55.856056,
              end: 56.219936,
              confidence: 0.34375,
              punctuated_word: "early",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0499992,
    start: 52.12,
    is_final: true,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "Feature javascript. There's an ongoing Tc thirty nine",
          confidence: 0.9614258,
          words: [
            {
              word: "feature",
              start: 52.399998,
              end: 52.719997,
              confidence: 0.4711914,
              punctuated_word: "Feature",
            },
            {
              word: "javascript",
              start: 52.719997,
              end: 53.039997,
              confidence: 0.86279297,
              punctuated_word: "javascript.",
            },
            {
              word: "there's",
              start: 53.44,
              end: 53.6,
              confidence: 0.9707031,
              punctuated_word: "There's",
            },
            {
              word: "an",
              start: 53.6,
              end: 54,
              confidence: 0.9926758,
              punctuated_word: "an",
            },
            {
              word: "ongoing",
              start: 54,
              end: 54.16,
              confidence: 0.9614258,
              punctuated_word: "ongoing",
            },
            {
              word: "tc",
              start: 54.32,
              end: 54.559998,
              confidence: 0.8652344,
              punctuated_word: "Tc",
            },
            {
              word: "thirty",
              start: 54.559998,
              end: 54.879997,
              confidence: 0.50439453,
              punctuated_word: "thirty",
            },
            {
              word: "nine",
              start: 54.879997,
              end: 55.17,
              confidence: 0.99365234,
              punctuated_word: "nine",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 2.009941,
    start: 55.17,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript: "disposal in its other stages to",
          confidence: 0.9536133,
          words: [
            {
              word: "disposal",
              start: 55.21,
              end: 55.53,
              confidence: 0.94873047,
              punctuated_word: "disposal",
            },
            {
              word: "in",
              start: 55.53,
              end: 55.69,
              confidence: 0.9536133,
              punctuated_word: "in",
            },
            {
              word: "its",
              start: 55.69,
              end: 55.85,
              confidence: 0.9316406,
              punctuated_word: "its",
            },
            {
              word: "other",
              start: 55.85,
              end: 56.17,
              confidence: 0.9785156,
              punctuated_word: "other",
            },
            {
              word: "stages",
              start: 56.17,
              end: 56.57,
              confidence: 0.98339844,
              punctuated_word: "stages",
            },
            {
              word: "to",
              start: 56.57,
              end: 57.07,
              confidence: 0.8105469,
              punctuated_word: "to",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.0299377,
    start: 55.17,
    is_final: false,
    speech_final: false,
    channel: {
      alternatives: [
        {
          transcript:
            "proposal in its early stages to introduce pet matching to choose",
          confidence: 0.89990234,
          words: [
            {
              word: "proposal",
              start: 55.209736,
              end: 55.52763,
              confidence: 0.6352539,
              punctuated_word: "proposal",
            },
            {
              word: "in",
              start: 55.52763,
              end: 55.686577,
              confidence: 0.9941406,
              punctuated_word: "in",
            },
            {
              word: "its",
              start: 55.686577,
              end: 55.845524,
              confidence: 0.9711914,
              punctuated_word: "its",
            },
            {
              word: "early",
              start: 55.845524,
              end: 56.163418,
              confidence: 0.80371094,
              punctuated_word: "early",
            },
            {
              word: "stages",
              start: 56.163418,
              end: 56.560787,
              confidence: 0.99853516,
              punctuated_word: "stages",
            },
            {
              word: "to",
              start: 56.560787,
              end: 56.958157,
              confidence: 0.99658203,
              punctuated_word: "to",
            },
            {
              word: "introduce",
              start: 56.958157,
              end: 57.19658,
              confidence: 0.84375,
              punctuated_word: "introduce",
            },
            {
              word: "pet",
              start: 57.19658,
              end: 57.514473,
              confidence: 0.89990234,
              punctuated_word: "pet",
            },
            {
              word: "matching",
              start: 57.514473,
              end: 57.991314,
              confidence: 0.68115234,
              punctuated_word: "matching",
            },
            {
              word: "to",
              start: 57.991314,
              end: 58.15026,
              confidence: 0.99072266,
              punctuated_word: "to",
            },
            {
              word: "choose",
              start: 58.15026,
              end: 58.199936,
              confidence: 0.74853516,
              punctuated_word: "choose",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 3.8100014,
    start: 55.17,
    is_final: true,
    speech_final: true,
    channel: {
      alternatives: [
        {
          transcript:
            "proposal in its early stages to introduce pet matching to transcript.",
          confidence: 0.9692383,
          words: [
            {
              word: "proposal",
              start: 55.28875,
              end: 55.52625,
              confidence: 0.69921875,
              punctuated_word: "proposal",
            },
            {
              word: "in",
              start: 55.52625,
              end: 55.68458,
              confidence: 0.9970703,
              punctuated_word: "in",
            },
            {
              word: "its",
              start: 55.68458,
              end: 55.842915,
              confidence: 0.9921875,
              punctuated_word: "its",
            },
            {
              word: "early",
              start: 55.842915,
              end: 56.15958,
              confidence: 0.9692383,
              punctuated_word: "early",
            },
            {
              word: "stages",
              start: 56.15958,
              end: 56.555416,
              confidence: 0.9995117,
              punctuated_word: "stages",
            },
            {
              word: "to",
              start: 56.555416,
              end: 56.95125,
              confidence: 0.9980469,
              punctuated_word: "to",
            },
            {
              word: "introduce",
              start: 56.95125,
              end: 57.188747,
              confidence: 0.9526367,
              punctuated_word: "introduce",
            },
            {
              word: "pet",
              start: 57.188747,
              end: 57.505413,
              confidence: 0.9379883,
              punctuated_word: "pet",
            },
            {
              word: "matching",
              start: 57.505413,
              end: 57.980415,
              confidence: 0.6191406,
              punctuated_word: "matching",
            },
            {
              word: "to",
              start: 57.980415,
              end: 58.217915,
              confidence: 0.98291016,
              punctuated_word: "to",
            },
            {
              word: "transcript",
              start: 58.217915,
              end: 58.613747,
              confidence: 0.5444336,
              punctuated_word: "transcript.",
            },
          ],
        },
      ],
    },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 0.95000076,
    start: 58.98,
    is_final: true,
    speech_final: true,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 0.29000092,
    start: 59.93,
    is_final: true,
    speech_final: true,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 0.44999695,
    start: 60.22,
    is_final: true,
    speech_final: true,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 0.5400009,
    start: 60.67,
    is_final: true,
    speech_final: true,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 0.45000076,
    start: 61.21,
    is_final: true,
    speech_final: true,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
  {
    type: "Results",
    channel_index: [0, 1],
    duration: 0.7799988,
    start: 61.66,
    is_final: true,
    speech_final: true,
    channel: { alternatives: [{ transcript: "", confidence: 0, words: [] }] },
    metadata: {
      request_id: "cbb8536e-fb5b-4246-a29a-9a241c645017",
      model_info: { name: "general", version: "2023-02-22.3", arch: "base" },
      model_uuid: "96a295ec-6336-43d5-b1cb-1e48b5e6d9a4",
    },
  },
];
