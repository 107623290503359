import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { createRoot } from "react-dom/client";
import Recorder from "./recorder/Recorder";
import "./styles.css";

createRoot(document.getElementById("app")!).render(
  <Theme>
    <Recorder />
    {/* <ThemePanel /> */}
  </Theme>
);
