import { Container } from "@radix-ui/themes";
import cookie from "js-cookie";
import { useState } from "react";
import Login from "./Login";
import RecorderInterface from "./RecorderInterface";

const Recorder: React.FC = () => {
  const [tokens, setTokens] = useState(() => {
    const appToken = cookie.get("appToken") || "";
    const deepgramToken = cookie.get("deepgramToken") || "";
    return { appToken, deepgramToken };
  });

  return (
    <>
      {tokens.appToken === "" || tokens.deepgramToken === "" ? (
        <Container size="1">
          <Login
            onAuthenticated={(newTokens) => {
              cookie.set("appToken", newTokens.appToken, { expires: 1 });
              cookie.set("deepgramToken", newTokens.deepgramToken, {
                expires: 1,
              });
              setTokens(newTokens);
            }}
          />
        </Container>
      ) : (
        <Container size="3">
          <RecorderInterface
            appToken={tokens.appToken}
            deepgramToken={tokens.deepgramToken}
          />
        </Container>
      )}
    </>
  );
};

export default Recorder;
