import * as S from "@effect/schema/Schema";

export const FinalTextMessage = S.struct({
  type: S.literal("final-text"),
  text: S.string,
  sessionId: S.string,
});

export const DraftTextMessage = S.struct({
  type: S.literal("draft-text"),
  text: S.string,
  sessionId: S.string,
});

export const TextMessage = S.union(FinalTextMessage, DraftTextMessage);

export const SessionMessage = S.struct({
  type: S.literal("session"),
  session: S.array(S.string),
  sessionId: S.string,
});

export const ResetSession = S.struct({
  type: S.literal("reset-session"),
  sessionId: S.string,
});

export const Message = S.union(
  FinalTextMessage,
  DraftTextMessage,
  SessionMessage,
  ResetSession
);
