import { Button, Text, TextField } from "@radix-ui/themes";
import { getEndpoint } from "@transcribe-session/shared";
import { useState } from "react";

type Props = {
  onAuthenticated: (result: {
    appToken: string;
    deepgramToken: string;
  }) => void;
};

const Login: React.FC<Props> = ({ onAuthenticated }) => {
  const [appToken, setAppToken] = useState("");

  const endpoint = getEndpoint();

  return (
    <>
      <p>Please provide the Recorder App Token:</p>
      <form>
        <TextField.Input
          mb="2"
          onChange={(event) => {
            setAppToken(event.target.value);
          }}
          value={appToken}
        />
        <Button
          mb="6"
          disabled={appToken === ""}
          onClick={async (event) => {
            event.preventDefault();
            try {
              const result = await fetch(
                `${endpoint}/parties/main/authenticate`,
                {
                  headers: {
                    Authorization: appToken,
                  },
                  method: "POST",
                }
              );
              const { deepgramToken } = await result.json();

              onAuthenticated({ appToken, deepgramToken });
            } catch (error) {
              alert("Invalid App Token");
            }
          }}
        >
          Login
        </Button>
      </form>
      <Text size="1">API Endpoint: {endpoint}</Text>
    </>
  );
};

export default Login;
